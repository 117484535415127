var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticClass: "box-card", attrs: { shadow: "always" } },
    [
      _c(
        "div",
        { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
        [
          _c("span", [_vm._v(_vm._s(_vm.title))]),
          _c(
            "el-button-group",
            { staticStyle: { float: "right" } },
            [
              _vm.active == 3
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPerm",
                          rawName: "v-hasPerm",
                          value: ["market:api:edit"],
                          expression: "['market:api:edit']",
                        },
                      ],
                      attrs: {
                        size: "mini",
                        icon: "el-icon-plus",
                        round: "",
                        loading: _vm.loadingOptions.loading,
                        disabled: _vm.loadingOptions.isDisabled,
                      },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(_vm._s(_vm.loadingOptions.loadingText))]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", icon: "el-icon-back", round: "" },
                  on: { click: _vm.showCard },
                },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.goBackBtn")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-steps",
            {
              attrs: {
                active: _vm.active,
                "finish-status": "success",
                "align-center": "",
              },
            },
            [
              _c("el-step", {
                attrs: { title: _vm.$t("cip.dc.dataapi.field.attrConfig") },
              }),
              _c("el-step", {
                attrs: { title: _vm.$t("cip.dc.dataapi.field.executeConfig") },
              }),
              _c("el-step", {
                attrs: { title: _vm.$t("cip.dc.dataapi.field.paramsConfig") },
              }),
            ],
            1
          ),
          _vm.active == 1
            ? _c(
                "el-form",
                {
                  ref: "form1",
                  attrs: {
                    model: _vm.form1,
                    rules: _vm.rules1,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("cip.dc.dataapi.field.apiName"),
                        prop: "apiName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder:
                            _vm.$t("cip.cmn.rule.inputWarning") +
                            _vm.$t("cip.dc.dataapi.field.apiName"),
                        },
                        model: {
                          value: _vm.form1.apiName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form1, "apiName", $$v)
                          },
                          expression: "form1.apiName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("cip.dc.dataapi.field.apiVersion"),
                        prop: "apiVersion",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder:
                            _vm.$t("cip.cmn.rule.inputWarning") +
                            _vm.$t("cip.dc.dataapi.field.apiVersion"),
                        },
                        model: {
                          value: _vm.form1.apiVersion,
                          callback: function ($$v) {
                            _vm.$set(_vm.form1, "apiVersion", $$v)
                          },
                          expression: "form1.apiVersion",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("cip.dc.dataapi.field.apiUrl"),
                        prop: "apiUrl",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder:
                            _vm.$t("cip.cmn.rule.inputWarning") +
                            _vm.$t("cip.dc.dataapi.field.apiUrl"),
                        },
                        model: {
                          value: _vm.form1.apiUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.form1, "apiUrl", $$v)
                          },
                          expression: "form1.apiUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("cip.dc.dataapi.field.reqMethod"),
                        prop: "reqMethod",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder:
                              _vm.$t("cip.cmn.rule.inputWarning") +
                              _vm.$t("cip.dc.dataapi.field.reqMethod"),
                          },
                          model: {
                            value: _vm.form1.reqMethod,
                            callback: function ($$v) {
                              _vm.$set(_vm.form1, "reqMethod", $$v)
                            },
                            expression: "form1.reqMethod",
                          },
                        },
                        _vm._l(_vm.reqMethodOptions, function (dict) {
                          return _c("el-option", {
                            key: dict.id,
                            attrs: {
                              label: dict.itemValue,
                              value: dict.itemText,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("cip.dc.dataapi.field.resType"),
                        prop: "resType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: "",
                            placeholder:
                              _vm.$t("cip.cmn.rule.inputWarning") +
                              _vm.$t("cip.dc.dataapi.field.resType"),
                          },
                          model: {
                            value: _vm.form1.resType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form1, "resType", $$v)
                            },
                            expression: "form1.resType",
                          },
                        },
                        _vm._l(_vm.resTypeOptions, function (dict) {
                          return _c("el-option", {
                            key: dict.itemValue,
                            attrs: {
                              label: dict.itemValue,
                              value: dict.itemText,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { display: "none" },
                      attrs: {
                        label: _vm.$t("cip.dc.dataapi.field.deny"),
                        prop: "deny",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: _vm.$t(
                            "cip.dc.dataapi.field.denyPlaceholder"
                          ),
                        },
                        model: {
                          value: _vm.form1.deny,
                          callback: function ($$v) {
                            _vm.$set(_vm.form1, "deny", $$v)
                          },
                          expression: "form1.deny",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { display: "none" },
                      attrs: {
                        label: _vm.$t("cip.dc.dataapi.field.rateLimit"),
                        prop: "rateLimit",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form1.rateLimit.enable,
                            callback: function ($$v) {
                              _vm.$set(_vm.form1.rateLimit, "enable", $$v)
                            },
                            expression: "form1.rateLimit.enable",
                          },
                        },
                        _vm._l(_vm.whetherOptions, function (dict) {
                          return _c(
                            "el-radio",
                            { key: dict.id, attrs: { label: dict.itemText } },
                            [_vm._v(_vm._s(dict.itemValue))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form1.rateLimit.enable === "1"
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { display: "none" },
                          attrs: {
                            label: _vm.$t("cip.dc.dataapi.field.limiting"),
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("cip.dc.dataapi.field.every"))
                          ),
                          _c("el-input-number", {
                            attrs: { "controls-position": "right", min: 1 },
                            model: {
                              value: _vm.form1.rateLimit.seconds,
                              callback: function ($$v) {
                                _vm.$set(_vm.form1.rateLimit, "seconds", $$v)
                              },
                              expression: "form1.rateLimit.seconds",
                            },
                          }),
                          _vm._v(
                            _vm._s(_vm.$t("cip.dc.dataapi.field.second")) +
                              "\n        "
                          ),
                          _c("el-input-number", {
                            attrs: { "controls-position": "right", min: 1 },
                            model: {
                              value: _vm.form1.rateLimit.times,
                              callback: function ($$v) {
                                _vm.$set(_vm.form1.rateLimit, "times", $$v)
                              },
                              expression: "form1.rateLimit.times",
                            },
                          }),
                          _vm._v(
                            _vm._s(_vm.$t("cip.dc.dataapi.field.times")) +
                              "\n      "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("cip.dc.dataapi.field.status"),
                        prop: "status",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.form1.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.form1, "status", $$v)
                            },
                            expression: "form1.status",
                          },
                        },
                        _vm._l(_vm.statusOptions, function (dict) {
                          return _c(
                            "el-radio",
                            { key: dict.id, attrs: { label: dict.itemText } },
                            [_vm._v(_vm._s(dict.itemValue))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("cip.dc.dataapi.field.remark"),
                        prop: "remark",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder:
                            _vm.$t("cip.cmn.rule.inputWarning") +
                            _vm.$t("cip.dc.dataapi.field.remark"),
                        },
                        model: {
                          value: _vm.form1.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form1, "remark", $$v)
                          },
                          expression: "form1.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.active == 2
            ? _c(
                "el-form",
                {
                  ref: "form2",
                  attrs: {
                    model: _vm.form2,
                    rules: _vm.rules2,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("cip.dc.dataapi.field.configType"),
                        prop: "configType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder:
                              _vm.$t("cip.cmn.rule.selectWarning") +
                              _vm.$t("cip.dc.dataapi.field.configType"),
                          },
                          on: { change: _vm.configTypeSelectChanged },
                          model: {
                            value: _vm.form2.configType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form2, "configType", $$v)
                            },
                            expression: "form2.configType",
                          },
                        },
                        _vm._l(_vm.configTypeOptions, function (dict) {
                          return _c("el-option", {
                            key: dict.id,
                            attrs: {
                              label: dict.itemValue,
                              value: dict.itemText,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("cip.dc.dataapi.field.sourceName"),
                        prop: "sourceId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder:
                              _vm.$t("cip.cmn.rule.selectWarning") +
                              _vm.$t("cip.dc.dataapi.field.sourceName"),
                          },
                          on: { change: _vm.sourceSelectChanged },
                          model: {
                            value: _vm.form2.sourceId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form2, "sourceId", $$v)
                            },
                            expression: "form2.sourceId",
                          },
                        },
                        _vm._l(_vm.sourceOptions, function (source) {
                          return _c("el-option", {
                            key: source.id,
                            attrs: {
                              label: source.sourceName,
                              value: source.id,
                              disabled: source.status === "0",
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form2.configType === "1"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("datacenter.datacolumn.tableName"),
                            prop: "tableName",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                "value-key": "id",
                                placeholder:
                                  _vm.$t("cip.cmn.rule.selectWarning") +
                                  _vm.$t("datacenter.datacolumn.tableName"),
                              },
                              on: { change: _vm.tableSelectChanged },
                              model: {
                                value: _vm.form2.table,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form2, "table", $$v)
                                },
                                expression: "form2.table",
                              },
                            },
                            _vm._l(_vm.tableOptions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.tableComment
                                    ? item.tableComment
                                    : item.tableName,
                                  value: item,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form2.configType === "1"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("cip.dc.dataapi.field.fieldList"),
                          },
                        },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%", margin: "15px 0" },
                              attrs: {
                                data: _vm.form2.fieldParams,
                                stripe: "",
                                border: "",
                                "max-height": 300,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "columnPosition",
                                  label: _vm.$t("datacenter.dataQuality.index"),
                                  width: "55",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "columnName",
                                  label: _vm.$t(
                                    "cip.dc.dataapi.field.columnName"
                                  ),
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "dataType",
                                  label: _vm.$t(
                                    "datacenter.datacolumn.dataType"
                                  ),
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "dataLength",
                                  label: _vm.$t(
                                    "datacenter.datacolumn.dataLength"
                                  ),
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "dataPrecision",
                                  label: _vm.$t(
                                    "datacenter.datacolumn.dataPrecision"
                                  ),
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "dataScale",
                                  label: _vm.$t(
                                    "datacenter.datacolumn.dataScale"
                                  ),
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "columnKey",
                                  label: _vm.$t(
                                    "datacenter.datacolumn.isPrimaryKey"
                                  ),
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.columnKey === "1"
                                            ? _c("span", [_vm._v("Y")])
                                            : _vm._e(),
                                          scope.row.columnKey === "0"
                                            ? _c("span", [_vm._v("N")])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3946021730
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "columnNullable",
                                  label: _vm.$t(
                                    "datacenter.datacolumn.isEmpty"
                                  ),
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.columnNullable === "1"
                                            ? _c("span", [_vm._v("Y")])
                                            : _vm._e(),
                                          scope.row.columnNullable === "0"
                                            ? _c("span", [_vm._v("N")])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2572120866
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "dataDefault",
                                  label: _vm.$t(
                                    "cip.dc.dataapi.field.columnDataDefault"
                                  ),
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "columnComment",
                                  label: _vm.$t(
                                    "cip.dc.dataapi.field.columnComment"
                                  ),
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "reqable",
                                  label: _vm.$t("cip.dc.dataapi.field.reqable"),
                                  align: "center",
                                  width: "50",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-checkbox", {
                                            attrs: {
                                              "true-label": "1",
                                              "false-label": "0",
                                            },
                                            on: {
                                              change: (checked) =>
                                                _vm.reqCheckChange(
                                                  scope.row,
                                                  checked
                                                ),
                                            },
                                            model: {
                                              value: scope.row.reqable,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "reqable",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.reqable",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1894821533
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "resable",
                                  label: _vm.$t("cip.dc.dataapi.field.resable"),
                                  align: "center",
                                  width: "50",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-checkbox", {
                                            attrs: {
                                              "true-label": "1",
                                              "false-label": "0",
                                            },
                                            on: {
                                              change: (checked) =>
                                                _vm.resCheckChange(
                                                  scope.row,
                                                  checked
                                                ),
                                            },
                                            model: {
                                              value: scope.row.resable,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "resable",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.resable",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1826560797
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form2.configType === "2"
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c("sql-editor", {
                                ref: "sqleditor",
                                staticStyle: {
                                  height: "300px",
                                  margin: "10px 10px",
                                },
                                attrs: { value: _vm.form2.sqlText },
                                on: {
                                  changeTextarea: function ($event) {
                                    return _vm.changeTextarea($event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form2.configType === "2"
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: { click: _vm.sqlParse },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("cip.dc.dataapi.field.sqlParsing")
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.active == 3
            ? _c(
                "el-form",
                {
                  ref: "form3",
                  attrs: { model: _vm.form3, "label-width": "80px" },
                },
                [
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _vm._v(_vm._s(_vm.$t("cip.dc.dataapi.field.reqParams"))),
                  ]),
                  _vm.form2.configType == "2"
                    ? _c("el-button", { on: { click: _vm.addreqParams } }, [
                        _vm._v("添加"),
                      ])
                    : _vm._e(),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%", margin: "15px 0" },
                      attrs: {
                        data: _vm.form3.reqParams,
                        stripe: "",
                        border: "",
                        "max-height": 300,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("datacenter.dataQuality.index"),
                          width: "55",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.$index + 1)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          423811938
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "paramName",
                          label: _vm.$t("cip.dc.dataapi.field.paramName"),
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.form2.configType == "2"
                                    ? _c("el-input", {
                                        attrs: {
                                          placeholder:
                                            _vm.$t(
                                              "cip.cmn.rule.inputWarning"
                                            ) +
                                            _vm.$t(
                                              "cip.dc.dataapi.field.paramName"
                                            ),
                                        },
                                        model: {
                                          value: scope.row.paramName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "paramName",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.paramName",
                                        },
                                      })
                                    : _c("span", [
                                        _vm._v(_vm._s(scope.row.paramName)),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2149535106
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "nullable",
                          label: _vm.$t("cip.dc.dataapi.field.nullable"),
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-checkbox", {
                                    attrs: {
                                      "true-label": "1",
                                      "false-label": "0",
                                    },
                                    model: {
                                      value: scope.row.nullable,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "nullable", $$v)
                                      },
                                      expression: "scope.row.nullable",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4251332491
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "paramComment",
                          label: _vm.$t("cip.dc.dataapi.field.paramComment"),
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder:
                                        _vm.$t("cip.cmn.rule.inputWarning") +
                                        _vm.$t(
                                          "cip.dc.dataapi.field.paramComment"
                                        ),
                                    },
                                    model: {
                                      value: scope.row.paramComment,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "paramComment", $$v)
                                      },
                                      expression: "scope.row.paramComment",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          782259066
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "paramType",
                          label: _vm.$t("cip.dc.dataapi.field.paramType"),
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder:
                                          _vm.$t("cip.cmn.rule.selectWarning") +
                                          _vm.$t(
                                            "cip.dc.dataapi.field.paramType"
                                          ),
                                      },
                                      model: {
                                        value: scope.row.paramType,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "paramType", $$v)
                                        },
                                        expression: "scope.row.paramType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.paramTypeOptions,
                                      function (dict) {
                                        return _c("el-option", {
                                          key: dict.id,
                                          attrs: {
                                            label: dict.itemValue,
                                            value: dict.itemText,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2281565125
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "whereType",
                          label: _vm.$t("cip.dc.dataapi.field.whereType"),
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder:
                                          _vm.$t("cip.cmn.rule.selectWarning") +
                                          _vm.$t(
                                            "cip.dc.dataapi.field.whereType"
                                          ),
                                      },
                                      model: {
                                        value: scope.row.whereType,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "whereType", $$v)
                                        },
                                        expression: "scope.row.whereType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.whereTypeOptions,
                                      function (dict) {
                                        return _c("el-option", {
                                          key: dict.id,
                                          attrs: {
                                            label: dict.itemValue,
                                            value: dict.itemText,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3386290119
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "exampleValue",
                          label: _vm.$t("cip.dc.dataapi.field.exampleValue"),
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder:
                                        _vm.$t("cip.cmn.rule.inputWarning") +
                                        _vm.$t(
                                          "cip.dc.dataapi.field.exampleValue"
                                        ),
                                    },
                                    model: {
                                      value: scope.row.exampleValue,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "exampleValue", $$v)
                                      },
                                      expression: "scope.row.exampleValue",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3558901658
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "defaultValue",
                          label: _vm.$t("cip.dc.dataapi.field.defaultValue"),
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder:
                                        _vm.$t("cip.cmn.rule.inputWarning") +
                                        _vm.$t(
                                          "cip.dc.dataapi.field.defaultValue"
                                        ),
                                    },
                                    model: {
                                      value: scope.row.defaultValue,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "defaultValue", $$v)
                                      },
                                      expression: "scope.row.defaultValue",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2869014266
                        ),
                      }),
                      _vm.form2.configType == "2"
                        ? _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("cip.dc.apiDComission.caozuo"),
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.delreqParams(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("cip.dc.apiDComission.del")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3048528631
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _vm._v(_vm._s(_vm.$t("cip.dc.dataapi.field.returnField"))),
                  ]),
                  _vm.form2.configType == "2"
                    ? _c("el-button", { on: { click: _vm.addresParams } }, [
                        _vm._v("添加"),
                      ])
                    : _vm._e(),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%", margin: "15px 0" },
                      attrs: {
                        data: _vm.form3.resParams,
                        stripe: "",
                        border: "",
                        "max-height": 300,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("datacenter.dataQuality.index"),
                          width: "55",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.$index + 1)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          423811938
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "fieldName",
                          label: _vm.$t("datacenter.datacolumn.fieldName"),
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder:
                                        _vm.$t("cip.cmn.rule.inputWarning") +
                                        _vm.$t(
                                          "datacenter.datacolumn.fieldName"
                                        ),
                                    },
                                    model: {
                                      value: scope.row.fieldName,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "fieldName", $$v)
                                      },
                                      expression: "scope.row.fieldName",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1226477392
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "fieldComment",
                          label: _vm.$t("cip.dc.dataapi.field.fieldComment"),
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder:
                                        _vm.$t("cip.cmn.rule.inputWarning") +
                                        _vm.$t(
                                          "cip.dc.dataapi.field.fieldComment"
                                        ),
                                    },
                                    model: {
                                      value: scope.row.fieldComment,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "fieldComment", $$v)
                                      },
                                      expression: "scope.row.fieldComment",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          110308442
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "dataType",
                          label: _vm.$t("datacenter.datacolumn.dataType"),
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder:
                                        _vm.$t("cip.cmn.rule.inputWarning") +
                                        _vm.$t(
                                          "datacenter.datacolumn.dataType"
                                        ),
                                    },
                                    model: {
                                      value: scope.row.dataType,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "dataType", $$v)
                                      },
                                      expression: "scope.row.dataType",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2466901232
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "exampleValue",
                          label: _vm.$t("cip.dc.dataapi.field.exampleValue"),
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder:
                                        _vm.$t("cip.cmn.rule.inputWarning") +
                                        _vm.$t(
                                          "cip.dc.dataapi.field.exampleValue"
                                        ),
                                    },
                                    model: {
                                      value: scope.row.exampleValue,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "exampleValue", $$v)
                                      },
                                      expression: "scope.row.exampleValue",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3558901658
                        ),
                      }),
                      _vm.form2.configType == "2"
                        ? _c("el-table-column", {
                            attrs: {
                              label: _vm.$t("cip.dc.apiDComission.caozuo"),
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.delresParams(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("cip.dc.apiDComission.del")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3759183925
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.active < 3
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "12px" },
                  on: { click: _vm.handleNextStep },
                },
                [_vm._v(_vm._s(_vm.$t("datacenter.btn.next")))]
              )
            : _vm._e(),
          _vm.active > 1
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "12px" },
                  on: { click: _vm.handleLastStep },
                },
                [_vm._v(_vm._s(_vm.$t("datacenter.btn.prev")))]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }