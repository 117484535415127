import { render, staticRenderFns } from "./DataApiExample.vue?vue&type=template&id=43512a17"
import script from "./DataApiExample.vue?vue&type=script&lang=js"
export * from "./DataApiExample.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/hse-web-227/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('43512a17')) {
      api.createRecord('43512a17', component.options)
    } else {
      api.reload('43512a17', component.options)
    }
    module.hot.accept("./DataApiExample.vue?vue&type=template&id=43512a17", function () {
      api.rerender('43512a17', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/business/datacenter/datacenter/market/dataapi/DataApiExample.vue"
export default component.exports