<template>
  <basic-container>
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="$t('cip.dc.dataapi.title')"
                 v-on="onHeadEvent">
    </head-layout>
    <el-form ref="queryForm" :model="queryParams" :inline="true" class="search-form">
      <el-form-item label="" prop="apiName">
        <el-input
          v-model="queryParams.apiName"
          :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.dataapi.title')"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery"></el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"></el-button>
      </el-form-item>
    </el-form>

    <!-- <el-row type="flex" justify="space-between">
      <el-col :span="12">
        <el-button-group>
          <el-button
            v-hasPerm="['market:api:add']"
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
          >新增</el-button>
        </el-button-group>
      </el-col>
    </el-row> -->

    <el-table
      v-loading="loading"
      :data="dataApiList"
      border
      tooltip-effect="dark"
      :size="tableSize"
      :height="tableHeight"
      style="width: 100%;"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column :label="$t('datacenter.dataQuality.index')" width="55" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index +1 }}</span>
        </template>
      </el-table-column>
      <template v-for="(item, index) in tableColumns">
        <el-table-column
          v-if="item.show"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :formatter="item.formatter"
          align="center"
          show-overflow-tooltip
        />
      </template>
      <el-table-column :label="$t('cip.cmn.btn.handel')" align="center" class-name="small-padding fixed-width" width="220px">
        <template slot-scope="scope">
            <el-button
              v-hasPerm="['market:api:edit']"
              size="mini"
              type="text"
              @click="handleEdit(scope.row)"
            >{{$t('cip.cmn.btn.revBtn')}}</el-button>
            <el-button
              v-hasPerm="['market:api:detail']"
              size="mini"
              type="text"
              @click="handleDetail(scope.row)"
            >{{$t('cip.cmn.btn.viewDataBtn')}}</el-button>
            <el-button
              v-hasPerm="['market:api:remove']"
              size="mini"
              type="text"
              @click="handleDelete(scope.row)"
            >{{$t('cip.cmn.btn.delBtn')}}</el-button>
            <el-button
              v-hasPerm="['market:api:copy']"
              size="mini"
              type="text"
              @click="handleCopy(scope.row)"
            >{{$t('cip.cmn.btn.copyBtn')}}</el-button>
            <el-button
              v-hasPerm="['market:api:release']"
              v-if="scope.row.status !== '2'"
              size="mini"
              type="text"
              @click="handleRelease(scope.row)"
            >{{$t('cip.cmn.btn.publishBtn')}}</el-button>
            <el-button
              v-hasPerm="['market:api:cancel']"
              v-if="scope.row.status === '2'"
              size="mini"
              type="text"
              @click="handleCancel(scope.row)"
            >{{$t('cip.cmn.btn.logout')}}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align:right;padding:10px 0;background:#ffffff"
      :page-sizes="[10, 20, 50, 100]"
      layout="total, sizes, prev, pager, next, jumper"
      :current-page.sync="queryParams.pageNum"
      :page-size.sync="queryParams.pageSize"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </basic-container>
</template>

<script>
import { pageDataApi, delDataApi, copyDataApi, releaseDataApi, cancelDataApi } from '@/api/market/dataapi'
import { getDicts } from '@/api/dict'
import { selectDictLabel } from '@/util/data-process'

export default {
  name: 'DataApiList',
  data() {
    return {
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.addBtn'),
        emit: "head-add",
        type: "button",
        icon: ""
      }],
      tableHeight: document.body.offsetHeight - 260 + 'px',
      // 展示切换
      showOptions: {
        data: {},
        showList: true,
        showAdd: false,
        showEdit: false,
        showDetail: false,
        showExample: false
      },
      // 遮罩层
      loading: true,
      // 表格头
      tableColumns: [
        { prop: 'apiName', label: this.$t('cip.dc.dataapi.field.apiName'), show: true },
        { prop: 'apiVersion', label: this.$t('cip.dc.dataapi.field.apiVersion'), show: true },
        { prop: 'apiUrl', label: this.$t('cip.dc.dataapi.field.apiUrl'), show: true },
        { prop: 'reqMethod', label: this.$t('cip.dc.dataapi.field.reqMethod'), show: true },
        { prop: 'resType', label: this.$t('cip.dc.dataapi.field.resType'), show: true },
        {
          prop: 'status',
          label: this.$t('cip.dc.dataapi.field.status'),
          show: true,
          formatter: this.statusFormatter
        },
        { prop: 'createTime', label: this.$t('cip.dc.dataapi.field.createTime'), show: true }
      ],
      // 默认选择中表格头
      checkedTableColumns: [],
      tableSize: 'small',
      // 状态数据字典
      statusOptions: [],
      // 数据集表格数据
      dataApiList: [],
      // 总数据条数
      total: 0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        apiName: ''
      }
    }
  },
  computed:{
    onHeadEvent() {
      return {
        'head-add': this.handleAdd
      }
    },
  },
  created() {
    getDicts('data_api_status').then(res => {
      let response = res.data;
      if (response.success) {
        this.statusOptions = response.data
      }
    })
    this.getList()
  },
  mounted() {
    this.initCols()
  },
  methods: {
    /** 查询数据Api列表 */
    getList() {
      this.loading = true
      pageDataApi(this.queryParams).then(res => {
        let response = res.data;
        this.loading = false
        if (response.success) {
          const { data } = response
          this.dataApiList = data.data
          this.total = data.total
        }
      })
    },
    initCols() {
      this.checkedTableColumns = this.tableColumns.map(col => col.prop)
    },
    handleCheckedColsChange(val) {
      this.tableColumns.forEach(col => {
        if (!this.checkedTableColumns.includes(col.prop)) {
          col.show = false
        } else {
          col.show = true
        }
      })
    },
    handleCommand(command) {
      this.tableSize = command
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 20,
        apiName: ''
      }
      this.handleQuery()
    },
    /** 刷新列表 */
    handleRefresh() {
      this.getList()
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.showOptions.data = {}
      this.showOptions.showList = false
      this.showOptions.showAdd = true
      this.showOptions.showEdit = false
      this.showOptions.showDetail = false
      this.showOptions.showExample = false
      this.$emit('showCard', this.showOptions)
    },
    /** 修改按钮操作 */
    handleEdit(row) {
      this.showOptions.data.id = row.id
      this.showOptions.showList = false
      this.showOptions.showAdd = false
      this.showOptions.showEdit = true
      this.showOptions.showDetail = false
      this.showOptions.showExample = false
      this.$emit('showCard', this.showOptions)
    },
    /** 详情按钮操作 */
    handleDetail(row) {
      this.showOptions.data.id = row.id
      this.showOptions.showList = false
      this.showOptions.showAdd = false
      this.showOptions.showEdit = false
      this.showOptions.showDetail = true
      this.showOptions.showExample = false
      this.$emit('showCard', this.showOptions)
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm(this.$t('datacenter.tips.del'), this.$t('tip'), {
        confirmButtonText: this.$t('submitText'),
        cancelButtonText: this.$t('cancelText'),
        type: 'warning'
      }).then(() => {
        delDataApi(row.id).then(res => {
          let response = res.data;
          if (response.success) {
            this.$message.success(this.$t('cip.cmn.msg.success.delSuccess'))
            this.getList()
          }
        })
      }).catch(() => {
      })
    },
    /** 接口拷贝 */
    handleCopy(row) {
      this.$confirm(this.$t('datacenter.tips.copy'), this.$t('tip'), {
        confirmButtonText: this.$t('submitText'),
        cancelButtonText: this.$t('cancelText'),
        type: 'warning'
      }).then(() => {
        copyDataApi(row.id).then(res => {
          let response = res.data;
          if (response.success) {
            this.$message.success(this.$t('datacenter.tips.copySuccess'))
            this.getList()
          }
        })
      }).catch(() => {
      })
    },
    /** 接口发布 */
    handleRelease(row) {
      releaseDataApi(row.id).then(res => {
        let response = res.data;
        if (response.success) {
          this.$message.success(this.$t('datacenter.tips.apiPublishedSuccess'))
          this.getList()
        }
      })
    },
    /** 接口注销 */
    handleCancel(row) {
      cancelDataApi(row.id).then(res => {
        let response = res.data;
        if (response.success) {
          this.$message.success(this.$t('datacenter.tips.apiLogoutSuccess'))
          this.getList()
        }
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.queryParams.pageNum = 1
      this.queryParams.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.queryParams.pageNum = val
      this.getList()
    },
    statusFormatter(row, column, cellValue, index) {
      const dictLabel = selectDictLabel(this.statusOptions, cellValue)
      if (cellValue === '1') {
        return <el-tag size="mini">{dictLabel}</el-tag>
      } else if (cellValue === '2') {
        return <el-tag type='success' size="mini">{dictLabel}</el-tag>
      } else if (cellValue === '3') {
        return <el-tag type='warning' size="mini">{dictLabel}</el-tag>
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.right-toolbar {
  float: right;
}
.search-form::v-deep{
  background: #FFFFFF;
   padding: 14px 12px;
   .el-form-item--small.el-form-item{
    margin-bottom: 0;
    }
  .el-form-item--small .el-form-item__content{
    line-height: 28px !important;
  }
  .el-button{
    height: 28px;
    margin-left:0;
   }
  .el-input__inner{
   height: 28px;
   line-height: 28px;
  }
}
::v-deep .el-table{
    height:calc(100vh - 274px) !important;
  }
  //修改表格边框颜色
  ::v-deep .el-table thead tr, .el-table thead tr th{
  background: #fafafa !important;
}
::v-deep .el-table--border, .el-table--group{
  border-color: #cccccc !important;
}
::v-deep .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
  border-color: #cccccc !important;
}
::v-deep .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{
  border-color: #cccccc !important;
}
::v-deep .el-table--border th.el-table__cell, .el-table__fixed-right-patch{
  border-color: #cccccc !important;
}
::v-deep .el-table__fixed-right::before {
  background-color: #ffffff !important;
}
::v-deep .el-table__fixed::before{
  background-color: #ffffff !important;
}
::v-deep .el-table--border::after, .el-table--group::after, .el-table::before{
  background-color: #cccccc !important;
}
::v-deep .el-table--border{
  border-bottom: 1px solid #cccccc;
}
</style>
